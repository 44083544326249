<template>
  <div>
    <div class="row">
      <div class="col-md-8 offset-md-2" id="whole">
        <div class="card" v-if="isEditAble">
          <div class="card-header">
            <h5>Edit leave request</h5>

            <div class="close">
              <router-link :to="{ name: 'leave.employee' }">
                <i class="fa fa-times"></i>
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <!-- {{ getLeaveDetails }} -->

            <div class="row">
              <div class="col-lg-8 offset-lg-2 col-md-12" id="d1">
                <form @submit.stop.prevent="onSubmit">
                  <div class="form-group">
                    <label for="">Leave Type</label>
                    <select
                      class="mb-3 mr-sm-3 mb-sm-0 custom-select"
                      :class="
                        errors
                          ? errors['leave_type_id'] && formdata.typeId == ''
                            ? 'is-invalid'
                            : ''
                          : ''
                      "
                      v-model="formdata.typeId"
                    >
                      <option value="">Select leave type</option>
                      <option
                        v-for="(leaveTypeItem, index) in getActiveLeaveTypes"
                        :key="index"
                        :value="leaveTypeItem.id"
                      >
                        {{ leaveTypeItem.name }}
                      </option>
                    </select>
                    <span
                      class="text-danger text-sm"
                      v-if="errors['leave_type_id'] && formdata.typeId == ''"
                    >
                      {{ errors["leave_type_id"][0] }}</span
                    >
                  </div>
                  <div class="form-group">
                    <label for="">From</label>
                    <input
                      :class="
                        errors
                          ? errors['from_date'] && formdata.from == ''
                            ? 'is-invalid'
                            : ''
                          : ''
                      "
                      type="date"
                      class="form-control"
                      v-model="formdata.from"
                    />
                    <span
                      class="text-danger text-sm"
                      v-if="errors['from_date'] && formdata.from == ''"
                    >
                      {{ errors["from_date"][0] }}</span
                    >
                  </div>

                  <div class="form-group">
                    <label for="">To</label>
                    <input
                      :class="
                        errors
                          ? errors['to_date'] && formdata.to == ''
                            ? 'is-invalid'
                            : ''
                          : ''
                      "
                      type="date"
                      class="form-control"
                      v-model="formdata.to"
                    />
                    <span
                      class="text-danger text-sm"
                      v-if="errors['to_date'] && formdata.to == ''"
                    >
                      {{ errors["to_date"][0] }}</span
                    >
                  </div>

                  <div class="form-group">
                    <label for="">Note</label>
                    <textarea
                      :class="
                        errors
                          ? errors['note'] && formdata.note == ''
                            ? 'is-invalid'
                            : ''
                          : ''
                      "
                      v-model="formdata.note"
                      class="form-control"
                      cols="30"
                      rows="4"
                      placeholder="Leave a note.."
                    ></textarea>
                    <span
                      class="text-danger text-sm"
                      v-if="errors['note'] && formdata.note == ''"
                    >
                      {{ errors["note"][0] }}</span
                    >
                  </div>

                  <button
                    class="btn btn-success btn-sm float-right"
                    ref="kt_edit_request"
                  >
                    Edit request
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="alert alert-danger" role="alert" v-if="!isEditAble">
          <h4 class="alert-heading">Can't Edit</h4>
          <p>
            This request has been accepted/declined by admin. So, you don't have
            permission to edit this leave request
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
#whole {
  .card {
    .card-header {
      padding: 16px !important;
      display: inline-flex;
      .close {
        border-left: 1px solid #ccc;
        height: 58px;
        width: 58px;
        /* background: black; */
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        text-align: center;
        i {
          line-height: 58px;
          font-size: 25px;

          color: #ccc;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
}
</style>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_ACTIVE_LEAVE_TYPE,
  EDIT_LEAVE_REQUEST,
  FETCH_EMPLOYEE_LEAVE_DETAILS,
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      errors: [],
      isEditAble: true,
     
      formdata: {
         id: 0,
        note: "",
        from: "",
        to: "",
        typeId: "",
        status: "0",
        employee_id: "",
      },
    };
  },

  mounted() {
    this.$store.dispatch(FETCH_ACTIVE_LEAVE_TYPE);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Leaves", route: "/leaves" },
      { title: "Edit Leave Request" },
    ]);
    this.$store
      .dispatch(FETCH_EMPLOYEE_LEAVE_DETAILS, this.$route.params.id)
      .then((data) => {
        if (data.status < 1) {
          this.isEditAble = true;
          this.formdata.note = data.note;
          this.formdata.typeId = data.leave_type_id;
          this.formdata.to = data.to;
          this.formdata.from = data.from;
          this.formdata.id = data.id;
        } else {
          this.isEditAble = false;
        }
      });
  },
  methods: {
    onSubmit() {
      const formdata = this.formdata;

      // s spinner to submit button
      const submitButton = this.$refs["kt_edit_request"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(EDIT_LEAVE_REQUEST, formdata)
        // go to which page after successfully login
        .then((response) => {
       
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;

          this.toast("success", "Success!", true, response.message);
          this.errors = [];
          this.$router.push({
            name: "leave.employee.details",
            params: { id: this.formdata.id },
          });
        })
        .catch((errors) => {
          this.errors = errors.errors;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.toast("danger", "Error!", true, errors.message);
        });
    },
    toast(variant = null, title, append = false, message) {
      this.$bvToast.toast(message, {
        title: title,
        toaster: "b-toaster-bottom-center",
        variant: variant,
        solid: true,
        appendToast: append,
      });
    },
  },

  computed: {
    ...mapGetters(["getActiveLeaveTypes"]),
  },
 
};
</script>
